import MeridianColumn from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import styled from '@emotion/styled';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchAttachmentsByReturnId } from '../../redux/actions/attachmentsActions';
import { getUnmappedItems } from '../../redux/actions/resellerActionCreators';
import {
    IAsynchronouslyLoadedValue,
    ReturnOrderLineItemStatus,
    returnOrderLineItemStatuses,
    ReturnOrderLineItem,
    ComplianceReview,
    ResellerItem,
    RootState,
    DEFAULT_ITEM_IMAGE_URL,
    ASIN_MISSING_PLACEHOLDER,
    ReturnOrderType,
} from '../../redux/types';
import { color } from '../../theme/colors';
import { PackageType } from '../../types';
import { Debug } from '../../utils/debug';
import { TranslatedString, useTranslation } from '../blocks';
import { HelpPopover, ItemImagePopover } from '../constructed';
import { ColumnFormat, SuperPaginatedTable, SuperPaginatedTableColumn } from '../constructed/SuperPaginatedTable';
import { externalLinkFormat, numberFormat, viewImageFormat } from '../constructed/SuperPaginatedTable.formats';
import { ColumnFilter } from './ColumnFilter';

const BorderedColumn = styled(MeridianColumn)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
    th button:focus {
        outline: thin dotted;
    }
`;

const ItemImage = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25em;
`;

export type ReturnOrderItemListProps = {
    resellerId: string;
    returnOrderId: string;
    items: IAsynchronouslyLoadedValue<ReturnOrderLineItem[]>;
    resellerItems: ResellerItem[];
    returnType: ReturnOrderType;
};

// eslint-disable-next-line max-lines-per-function
export const ReturnOrderItemList: FC<ReturnOrderItemListReduxProps & ReturnOrderItemListProps> = ({
    resellerId,
    returnOrderId,
    items,
    resellerItems,
    returnType,
    asinToImageUrls,
    attachments,
    fetchAttachmentsByReturnId,
    unmappedItemStatus,
    getUnmappedItems,
}) => {
    const { t } = useTranslation(['returnOrderItemList', 'uploadImageModal']);

    const [itemFilterValue, setItemFilterValue] = useState<string | undefined>(undefined);
    const [statusFilterValue, setStatusFilterValue] = useState<string | undefined>(undefined);
    const [areAttachmentsCleared, setAreAttachmentsCleared] = useState<boolean>(false);

    useEffect(() => {
        if (attachments.status === 'Uninitialized' || !areAttachmentsCleared) {
            setAreAttachmentsCleared(true);
            fetchAttachmentsByReturnId({ returnOrderId });
        }
    }, [attachments, fetchAttachmentsByReturnId, returnOrderId, areAttachmentsCleared]);

    const itemMap = useMemo(() => new Map(resellerItems.map((item) => [item.itemId, item])), [resellerItems]);

    useEffect(() => {
        if (resellerId) {
            const unmappedItemIds =
                items.value && items.value.map((item) => item.itemId).filter((itemId) => !itemMap.has(itemId));
            if (unmappedItemIds && unmappedItemIds.length > 0 && unmappedItemStatus === 'Uninitialized') {
                getUnmappedItems(resellerId, unmappedItemIds);
            }
        }
    }, [resellerId, unmappedItemStatus, itemMap, items, resellerItems, getUnmappedItems]);

    const getTitle = useCallback(
        (itemId: string): TranslatedString => {
            const title = itemMap.get(itemId)?.title;
            return title ? t.cognate(title) : t('title-value_missing');
        },
        [itemMap, t]
    );

    const getSku = useCallback(
        (itemId: string): TranslatedString => {
            const externalSku = itemMap.get(itemId)?.externalSku;
            return externalSku ? t.cognate(externalSku) : t('externalSku-value_missing');
        },
        [itemMap, t]
    );

    const getUpc = useCallback(
        (itemId: string): TranslatedString => {
            const upc = itemMap.get(itemId)?.upc;
            return upc ? t.cognate(upc) : t('upc-value_missing');
        },
        [itemMap, t]
    );

    const getRawSku = useCallback(
        (itemId: string): string | undefined => {
            return itemMap.get(itemId)?.externalSku;
        },
        [itemMap]
    );

    const getAsin = useCallback(
        (itemId: string): TranslatedString => {
            const asin = itemMap.get(itemId)?.asin || t('asin-value_missing');
            return t.cognate(asin);
        },
        [itemMap, t]
    );

    const allItems = useMemo(
        () =>
            _.chain(items.value ?? [])
                .map((item) => {
                    return getSku(item.itemId);
                })
                .uniq()
                .value(),
        [getSku, items.value]
    );

    const getItemImageUrlFromStore = useCallback(
        (itemId: string): string | undefined => {
            const asin = itemMap.get(itemId)?.asin || ASIN_MISSING_PLACEHOLDER;
            return asinToImageUrls.value?.get(asin) || DEFAULT_ITEM_IMAGE_URL;
        },
        [asinToImageUrls, itemMap]
    );

    const getSearchItemId = useCallback(
        (itemId: string): TranslatedString => {
            const itemIdValues = `${getTitle(itemId)} ${getSku(itemId)} ${getUpc(itemId)} ${getAsin(itemId)}`;
            return t.cognate(itemIdValues);
        },
        [getAsin, getUpc, getSku, getTitle, t]
    );

    const skuFormat: ColumnFormat = useMemo(() => {
        const getText = (itemId: string) => getSku(itemId);

        return {
            render: getText,
            sort: getText,
            search: getText,
        };
    }, [getSku]);

    const upcFormat: ColumnFormat = useMemo(() => {
        const getText = (itemId: string) => getUpc(itemId);

        return {
            render: getText,
            sort: getText,
            search: getText,
        };
    }, [getUpc]);

    const asinFormat: ColumnFormat = useMemo(() => {
        const getText = (itemId: string) => itemMap.get(itemId)?.asin || t('asin-value_missing');

        return {
            render: getText,
            sort: getText,
            search: getText,
        };
    }, [itemMap, t]);

    const itemStatusFormat: ColumnFormat = useMemo(() => {
        const getText = (status: ReturnOrderLineItemStatus) => t('itemStatus-value', { context: status });

        return {
            render: (status: ReturnOrderLineItemStatus, item: ReturnOrderLineItem) => {
                switch (status) {
                    case 'ON_CREDIT_MEMO': {
                        // TODO: use a real URL.
                        const externalLinkRenderFunction = externalLinkFormat(
                            (status: ReturnOrderLineItemStatus, item: ReturnOrderLineItem) =>
                                `http://kor-portal.amazon.com/credit-memos/${item.creditMemoNumber}`,
                            { render: getText }
                        ).render;
                        Debug.assertExists(externalLinkRenderFunction);
                        return externalLinkRenderFunction(status, item);
                    }
                    default:
                        return getText(status);
                }
            },
            sort: getText,
            search: getText,
        };
    }, [t]);

    const titleFormat: ColumnFormat = useMemo(() => {
        const getText = (itemId: string) => getTitle(itemId);

        return {
            render: getText,
            sort: getText,
            search: getSearchItemId,
        };
    }, [getSearchItemId, getTitle]);

    const imageFormat: ColumnFormat = useMemo(() => {
        const getText = (itemId: string) => getTitle(itemId);
        return {
            render: (itemId: string) => {
                const translatedTitle = t.cognate(getText(itemId));
                return (
                    <ItemImagePopover
                        thumbnailUrl={getItemImageUrlFromStore(itemId)}
                        header={translatedTitle}
                        dataTestId={`${itemId}_ItemImagerPopover`}
                    >
                        <MeridianColumn alignmentHorizontal={'left'} alignmentVertical={'top'} spacing={'xxsmall'}>
                            <Text>{getSku(itemId)}</Text>
                            <ItemImage
                                src={getItemImageUrlFromStore(itemId)}
                                data-testid={`${itemId}-Image`}
                                aria-label={translatedTitle}
                            />
                        </MeridianColumn>
                    </ItemImagePopover>
                );
            },
            sort: getText,
            search: getText,
        };
    }, [getTitle, t, getSku, getItemImageUrlFromStore]);

    const complianceFormat = (getText: (complianceReview: ComplianceReview) => TranslatedString) => ({
        render: getText,
    });

    const complianceEligibleQuantityFormat: ColumnFormat = useMemo(
        () =>
            complianceFormat((complianceReview: ComplianceReview) =>
                complianceReview.eligibleReturnQuantity
                    ? t.cognate(`${complianceReview.eligibleReturnQuantity}`)
                    : t('eligibleQuantity-value_notApplicable')
            ),
        [t]
    );

    const reasonFormat: ColumnFormat = useMemo(() => {
        const getText = (lineItemId: string) => {
            const item = items.value?.find((orderItem) => orderItem.lineItemId === lineItemId);
            return item?.reason?.toString() || '';
        };

        const getSearchText = (lineItemId: string) => {
            const item = items.value?.find((orderItem) => orderItem.lineItemId === lineItemId);
            if (!item) return '';

            const reasonText = item.reason ? t('orderItemReason', { context: item.reason }) : '';
            const disposeText = item.complianceResolution === 'DISPOSE' ? t('complianceResolution_DISPOSE') : '';

            return `${reasonText} ${disposeText}`.trim();
        };

        const getRenderedText = (lineItemId: string) => {
            const item = items.value?.find((orderItem) => orderItem.lineItemId === lineItemId);
            if (!item) return <></>;

            return (
                <Row>
                    {item.reason && <Text>{t('orderItemReason', { context: item.reason })}</Text>}
                    {item.complianceResolution === 'DISPOSE' && (
                        <>
                            <Text>{t('complianceResolution_DISPOSE')}</Text>
                            {item.itemId && getRawSku(item.itemId)?.startsWith('NO SKU') && (
                                <HelpPopover
                                    text={t('noRefund_for_NOSKU_item')}
                                    position={'right'}
                                    maxWidth={'60%'}
                                    dataTestId={`noRefundPop-${item.itemId}`}
                                />
                            )}
                        </>
                    )}
                </Row>
            );
        };

        return {
            render: getRenderedText,
            sort: getText,
            search: getSearchText,
        };
    }, [items.value, t, getRawSku]);

    const packageTypeFormat: ColumnFormat = useMemo(() => {
        const getText = (packageType?: PackageType) => {
            if (packageType) {
                return t('packageType', { context: packageType });
            } else {
                return t('packageType-value_missing');
            }
        };

        return {
            render: getText,
        };
    }, [t]);

    const packageQuantityFormat: ColumnFormat = {
        render: (packageQuantity: number) => {
            if (packageQuantity > 0) {
                return packageQuantity.toString();
            } else {
                return t('packageQuantity-value_missing');
            }
        },
    };

    const baseColumns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'lineItemId',
            id: 'lineItemId',
            title: t.cognate('lineItemId'),
            isKey: true,
            isVisible: false,
        },
        {
            sourceProperty: 'itemId',
            id: 'sku',
            title: t('sku-columnHeader'),
            defaultSort: 'ascending',
            format: skuFormat,
            searchable: true,
        },
        {
            sourceProperty: 'itemId',
            id: 'upc',
            title: t('upc-columnHeader'),
            defaultSort: 'ascending',
            format: upcFormat,
            searchable: true,
        },
        {
            sourceProperty: 'itemId',
            id: 'asin',
            title: t('asin-columnHeader'),
            format: asinFormat,
            searchable: true,
        },
        {
            sourceProperty: 'itemId',
            id: 'Title',
            title: t('item-columnHeader'),
            format: titleFormat,
            searchable: true,
        },
        {
            sourceProperty: 'itemId',
            id: 'image',
            title: t.cognate(' '),
            format: imageFormat,
            searchable: true,
        },
    ];

    const standardColumns: SuperPaginatedTableColumn[] = baseColumns.concat([
        {
            sourceProperty: 'requestedCount',
            title: t('requested-columnHeader'),
            format: numberFormat,
            searchable: false,
        },
        {
            sourceProperty: 'approvedCount',
            title: t('approved-columnHeader'),
            format: numberFormat,
            searchable: false,
        },
        {
            sourceProperty: 'receivedCount',
            title: t('received-columnHeader'),
            format: numberFormat,
            searchable: false,
        },
        {
            sourceProperty: 'status',
            title: t('status-columnHeader'),
            format: itemStatusFormat,
            searchable: true,
        },
        {
            sourceProperty: 'lineItemId',
            title: t('reason-columnHeader'),
            format: reasonFormat,
            searchable: true,
        },
        {
            sourceProperty: 'complianceReview',
            title: t('eligibleQuantity-columnHeader'),
            format: complianceEligibleQuantityFormat,
            searchable: true,
        },
    ]);

    const buybackRefusalColumns: SuperPaginatedTableColumn[] = baseColumns.concat([
        {
            sourceProperty: 'packageType',
            title: t('packageType-columnHeader'),
            format: packageTypeFormat,
            searchable: true,
        },
        {
            sourceProperty: 'packageQuantity',
            title: t('packageQuantity-columnHeader'),
            format: packageQuantityFormat,
            searchable: false,
        },
        {
            sourceProperty: 'requestedCount',
            title: t('requested-columnHeader'),
            format: numberFormat,
            searchable: false,
        },
        {
            sourceProperty: 'approvedCount',
            title: t('approved-columnHeader'),
            format: numberFormat,
            searchable: false,
        },
        {
            sourceProperty: 'receivedCount',
            title: t('received-columnHeader'),
            format: numberFormat,
            searchable: false,
        },
        {
            sourceProperty: 'status',
            title: t('status-columnHeader'),
            format: itemStatusFormat,
            searchable: true,
        },
        {
            sourceProperty: 'lineItemId',
            title: t('uploadImageModal:uploadedImages-columnHeader'),
            format: viewImageFormat(attachments),
            searchable: false,
        },
    ]);

    const allStatuses = useMemo(
        () => _.map(returnOrderLineItemStatuses, (status) => t('itemStatus-value', { context: status })),
        [t]
    );

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <SuperPaginatedTable
                search={[
                    { columns: ['sku'], value: itemFilterValue, matchWholeString: true },
                    { columns: ['status'], value: statusFilterValue, matchWholeString: true },
                ]}
                data={items.status === 'Loaded' ? _.uniqBy(items.value ?? [], 'lineItemId') : []}
                layout={{
                    pageSize: 10,
                    searchPlaceholder: t('findResources-searchBoxPlaceholder'),
                    columns: returnType === 'RETURN' ? standardColumns : buybackRefusalColumns,
                }}
            >
                <ColumnFilter
                    label={t('itemFilter-placeholder')}
                    values={allItems}
                    value={itemFilterValue}
                    onChange={setItemFilterValue}
                />
                <ColumnFilter
                    label={t('statusFilter-placeholder')}
                    values={allStatuses}
                    value={statusFilterValue}
                    onChange={setStatusFilterValue}
                />
            </SuperPaginatedTable>
        </BorderedColumn>
    );
};

const mapStateToProps = ({ resellerReducer, attachmentsReducer }: RootState) => {
    return {
        asinToImageUrls: resellerReducer.asinToImageUrls,
        attachments: attachmentsReducer,
        unmappedItemStatus: resellerReducer.unmappedItemStatus,
    };
};

const mapDispatchToProps = {
    fetchAttachmentsByReturnId,
    getUnmappedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReturnOrderItemListReduxProps = ConnectedProps<typeof connector>;
export default connector(ReturnOrderItemList);
