import Box from '@amzn/meridian/box';
import MeridianColumn from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import styled from '@emotion/styled';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHrpsApi } from '../clients/useHrpsApi';
import { TranslatedString, useTranslation } from '../components/blocks';
import { usePageMessaging } from '../components/composites';
import { IssueDetailDialog } from '../components/constructed';
import {
    ColumnFormat,
    SuperPaginatedTable,
    SuperPaginatedTableColumn,
} from '../components/constructed/SuperPaginatedTable';
import { RootState, IAsynchronouslyLoadedValue } from '../redux/types';
import { color } from '../theme/colors';
import { GetIssuesInput, GetIssuesOutput, Issue } from '../types';
import { Logger } from '../utils/logger';

const BorderedColumn = styled(MeridianColumn)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

const GreyBox = styled(Box)`
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

const NotificationListPage: FC<NotificationListProps> = ({ userState, resellerId }) => {
    const { t } = useTranslation(['notificationList', 'issueDetailsDialog']);
    const [issues, setIssues] = useState<IAsynchronouslyLoadedValue<Issue[]>>({ status: 'Uninitialized' });
    const { showError } = usePageMessaging();
    const issueMap = useMemo(() => new Map(issues.value?.map((issue) => [issue.id, issue])), [issues]);

    const {
        issues: { getIssues },
    } = useHrpsApi();

    useEffect(() => {
        if (issues.status === 'Uninitialized') {
            const resultSet: IAsynchronouslyLoadedValue<Issue[]> = {
                status: 'Loading',
                value: undefined,
            } as IAsynchronouslyLoadedValue<Issue[]>;

            setIssues(resultSet);
            const input: GetIssuesInput = {
                resellerId: resellerId,
            };
            getIssues(input)
                .then((result: GetIssuesOutput) => {
                    const resultSet: IAsynchronouslyLoadedValue<Issue[]> = {
                        status: 'Loaded',
                        value: result.issues,
                    } as IAsynchronouslyLoadedValue<Issue[]>;

                    setIssues(resultSet);
                })
                .catch((error: string) => {
                    Logger.debug(`Unable to load issue, because of: ${error}`);
                    const resultSet: IAsynchronouslyLoadedValue<Issue[]> = {
                        status: 'Failed',
                        value: undefined,
                    } as IAsynchronouslyLoadedValue<Issue[]>;

                    setIssues(resultSet);
                    showError(t('loadIssues-errorMessage'));
                });
        }
    }, [getIssues, issues.status, resellerId, setIssues, showError, t]);

    const itemFormat = (getText: (itemId: string) => TranslatedString) => ({
        render: getText,
    });

    const statusFormat: ColumnFormat = useMemo(() => {
        return itemFormat((status: string) => {
            return t('issueDetailsDialog:issueStatus-value', { context: status });
        });
    }, [t]);

    const resultDateFormat: ColumnFormat = useMemo(() => {
        return {
            render: (date: Date) => {
                return t('issueDate-fieldValue', { dateTime: date });
            },
            sort: (date: Date) => {
                return new Date(date).getTime().toString();
            },
            search: (date: Date) => {
                return t('issueDate-fieldValue', { dateTime: date });
            },
        };
    }, [t]);

    function getNotifications(): Issue[] {
        if (issues.status === 'Loaded') {
            return issues.value!;
        } else {
            return [] as Issue[];
        }
    }

    const columns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'id',
            title: t('issueId-columnHeader'),
            isKey: true,
            isVisible: true,
            format: {
                render: (issue: string) => {
                    const issueObject = issueMap.get(issue)!;
                    return (
                        <IssueDetailDialog
                            label={t.cognate(issue)}
                            issue={issueObject}
                            user={userState.user!}
                            resellerId={resellerId}
                        />
                    );
                },
                sort: (columnValue) => String(columnValue),
                search: (columnValue) => String(columnValue),
            },
        },
        {
            sourceProperty: 'title',
            title: t('title-columnHeader'),
        },
        {
            sourceProperty: 'status',
            title: t('status-columnHeader'),
            format: statusFormat,
        },
        {
            sourceProperty: 'submitter',
            title: t('submitter-columnHeader'),
        },
        {
            sourceProperty: 'createDate',
            title: t('creationDate-columnHeader'),
            format: resultDateFormat,
        },
        {
            sourceProperty: 'lastUpdatedDate',
            title: t('lastUpdatedDate-columnHeader'),
            defaultSort: 'descending',
            format: resultDateFormat,
        },
    ];

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <GreyBox type={'fill'} spacingInset={'small'} width={'100%'}>
                <Heading level={5} alignment={'left'} data-testid={'NotificationHeader'}>
                    {issues.status === 'Loaded'
                        ? t('notification-tableTitle', { count: issues.value!.length })
                        : t('notification-tableTitle', { context: 'loading' })}
                </Heading>
            </GreyBox>

            <SuperPaginatedTable
                search={[
                    {
                        columns: ['id', 'title', 'status', 'submitter', 'creationDate', 'lastUpdatedDate'],
                        matchWholeString: false,
                    },
                ]}
                data={getNotifications()}
                layout={{
                    pageSize: 10,
                    searchPlaceholder: t('findResources-searchBoxPlaceholder'),
                    columns: columns,
                }}
            />
        </BorderedColumn>
    );
};

const mapStateToProps = ({ userReducer, resellerReducer }: RootState) => {
    return {
        userState: userReducer,
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
    };
};

const connector = connect(mapStateToProps);
export type NotificationListProps = ConnectedProps<typeof connector>;
export default connector(NotificationListPage);
